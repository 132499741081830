import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";
import { mapConfig } from "src/app-config";

const config = [
  {
    name: "MapPage",
    rowProps: { noGutters: true },
    moduleComponentName: "MapPage",
    moduleProps: {
      content: {
        title: "Choose a Topic",
        topics: [
          {
            id: "overview",
            title: "Overview",
            locationOptions: {},
            layerOptions: [],
            content: {
              elementId: "overview",
              hideContent: true,
              layerControls: [],
            },
          },
          {
            id: "construction",
            title: "Construction",
            locationOptions: {},
            layerOptions: [
              { id: "tece-cia-updated", visible: false },
              { id: "tece-main-construction-compound", visible: false },
              { id: "tece-proposed-haulage-routes-updated", visible: false },
            ],
            content: {
              elementId: "construction",
              hideContent: false,
              layerControls: [
                {
                  type: "single",
                  layer: {
                    ids: ["tece-cia-updated"],
                    label:
                      "Indicative Construction impact area (area not to be fully cleared of vegetation)",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-main-construction-compound"],
                    label:
                      "Main construction compounds and accommodation sites",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-proposed-haulage-routes-updated"],
                    label: "Proposed haulage routes",
                  },
                },
              ],
            },
          },
          {
            id: "stakeholder-community-engagement",
            title: "Stakeholder and Community Engagement",
            locationOptions: {},
            layerOptions: [],
            content: {
              elementId: "stakeholder-community-engagement",
              hideContent: false,
              layerControls: [],
            },
          },
          {
            id: "biodiversity",
            title: "Biodiversity",
            locationOptions: {},
            layerOptions: [
              { id: "tece-ibra-symbol", visible: false },
              { id: "tece-ibra", visible: false },
              { id: "tece-mitchell-landscape", visible: false },
              { id: "tece-threatened-fauna", visible: false },
              { id: "tece-threatened-flora", visible: false },
              { id: "tece-threatened-flora-poly", visible: false },
              { id: "tece-fieldveg", visible: false },
            ],
            content: {
              elementId: "biodiversity",
              hideContent: true,
              layerControls: [
                {
                  type: "single",
                  layer: {
                    ids: ["tece-ibra", "tece-ibra-symbol"],
                    label: "IBRA regions and subregions",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-threatened-flora"],
                    label: "Threatened flora",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-threatened-flora-poly"],
                    label: "Threatened flora (polygon)",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-threatened-fauna"],
                    label: "Threatened fauna",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-mitchell-landscape"],
                    label: "Mitchell Landscapes",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-fieldveg"],
                    label: "Field verified vegetation",
                  },
                },
              ],
            },
          },
          {
            id: "heritage",
            title: "Heritage",
            locationOptions: {},
            layerOptions: [
              { id: "tece-previously-recorded-ahims", visible: false },
              { id: "tece-new-recorded-ahims", visible: false },
              { id: "tece-non-aboriginal-heritage-site", visible: false },
              { id: "tece-non-aboriginal-heritage-site-text", visible: false },
            ],
            content: {
              elementId: "heritage",
              hideContent: true,
              layerControls: [
                {
                  type: "single",
                  layer: {
                    ids: ["tece-previously-recorded-ahims"],
                    label:
                      "Aboriginal Heritage Information Management System (AHIMS) sites",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-new-recorded-ahims"],
                    label: "Aboriginal sites previously unrecorded",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: [
                      "tece-non-aboriginal-heritage-site",
                      "tece-non-aboriginal-heritage-site-text",
                    ],
                    label: "Non-Aboriginal heritage sites",
                  },
                },
              ],
            },
          },
          {
            id: "landscape-visual",
            title: "Landscape and Visual",
            locationOptions: {},
            layerOptions: [
              { id: "photo-points-viewpoint", visible: true },
              { id: "photo-points-photomontage", visible: true },
            ],
            content: {
              elementId: "landscape-visual",
              hideContent: false,
              layerControls: [
                {
                  type: "single",
                  layer: {
                    ids: [
                      "photo-points-viewpoint",
                      "photo-points-photomontage",
                    ],
                    label: "Viewpoints and photmontages",
                  },
                },
              ],
            },
          },
          {
            id: "noise-vibration",
            title: "Noise and Vibration",
            locationOptions: {},
            layerOptions: [
              { id: "tece-construction-noise-buffer-standard", visible: false },
              { id: "tece-construction-noise-buffer-day", visible: false },
              { id: "tece-construction-noise-buffer-night", visible: false },
              { id: "tece-operational-noise-transmission", visible: false },
            ],
            content: {
              elementId: "noise-vibration",
              hideContent: false,
              layerControls: [
                {
                  type: "single",
                  layer: {
                    ids: ["tece-construction-noise-buffer-standard"],
                    label:
                      "Construction noise buffer - Transmission line works - Standard Hours",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-construction-noise-buffer-day"],
                    label:
                      "Construction noise buffer - Transmission line works - Out of Hours (day)",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-construction-noise-buffer-night"],
                    label:
                      "Construction noise buffer - Transmission line works - Out of Hours (night)",
                  },
                },
                {
                  type: "single",
                  layer: {
                    ids: ["tece-operational-noise-transmission"],
                    label:
                      "Operational noise - Transmission line - Audible noise",
                  },
                },
              ],
            },
          },
          {
            id: "other-issues",
            title: "Other Issues",
            locationOptions: {},
            layerOptions: [
              { id: "tece-registered-gw-boreholes", visible: false },
              { id: "tece-terrestrial-gde", visible: false },
              { id: "tece-aquatic-gde", visible: false },
              { id: "tece-catchments", visible: false },
              { id: "tece-landuse", visible: false },
              { id: "tece-landuse-crown", visible: false },
              { id: "tece-landuse-inherent-soil-fertility", visible: false },
              { id: "tece-flood-depth", visible: false },
            ],
            content: {
              elementId: "other-issues",
              hideContent: true,
              layerControls: [
                {
                  type: "group",
                  title: "Groundwater",
                  layers: [
                    {
                      ids: ["tece-registered-gw-boreholes"],
                      label: "Registered groundwater bores",
                    },
                    {
                      ids: ["tece-terrestrial-gde"],
                      label: "Terrestrial Groundwater dependant ecosystems",
                    },
                    {
                      ids: ["tece-aquatic-gde"],
                      label: "Terrestrial Groundwater dependant ecosystems",
                    },
                  ],
                },
                {
                  type: "group",
                  title: "Hydrology and flooding",
                  layers: [
                    {
                      ids: ["tece-catchments"],
                      label: "Water catchments",
                    },
                    {
                      ids: ["tece-flood-depth"],
                      label: "Peak flooding depth",
                    },
                  ],
                },
                {
                  type: "group",
                  title: "Land use and property",
                  layers: [
                    {
                      ids: ["tece-landuse"],
                      label: "Existing land use",
                    },
                    {
                      ids: ["tece-landuse-crown"],
                      label: "Crown land",
                    },
                    {
                      ids: ["tece-landuse-inherent-soil-fertility"],
                      label: "Inherent soil fertility",
                    },
                  ],
                },
              ],
            },
          },
          {
            id: "eis-links",
            title: "Links to the EIS",
            locationOptions: {},
            layerOptions: [],
            content: {
              elementId: "eis-links",
              hideContent: true,
              layerControls: [],
            },
          },
        ],
      },
      geocoderConfig: mapConfig.geocoderConfig,
      icons: mapConfig.icons,
      layerControls: mapConfig.layerControls,
      layers: mapConfig.layers.reverse(),
      mapConfig: mapConfig.mapComponentConfig,
      sites: mapConfig.sites,
      popups: mapConfig.popups,
    },
  },
];

const InteractiveMapPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default InteractiveMapPage;
